<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card max-width="1100" width="100%" flat class="transparent mb-0 mb-md-5">
        <v-card-title flat class="transparent">
          <div class="d-flex">
            <h1 class="headline">{{ $t('medicalTests.nomenclator') }}</h1>
          </div>
          <v-spacer />
          <v-btn depressed color="primary" class="order-md-2" :to="{ name: paths.createNomenclator }">
            {{ $t('medicalTests.new_act') }}
          </v-btn>
        </v-card-title>

        <v-layout wrap>
          <v-flex xs12 sm4 lg4>
            <v-select
              v-model="filter.specialtyId"
              :label="`${$t('medicalTests.specialty')} *`"
              outlined
              dense
              class="mr-sm-1"
              :items="activeSpecialities"
              item-text="description"
              item-value="id"
              :loading="isLoadingSpecialties"
              :disabled="!activeSpecialities.length"
              clearable
              @click:clear="cleanSpecialty"
            />
          </v-flex>
          <v-flex xs12 sm4 lg4>
            <v-select
              v-model="filter.groupId"
              :label="`${$t('medicalTests.group')} *`"
              outlined
              dense
              class="mr-sm-1"
              :items="activeGroups"
              item-text="description"
              item-value="id"
              :loading="isLoadingGroups"
              :disabled="!activeGroups.length"
            />
          </v-flex>
          <v-flex xs12 sm4 lg4>
            <v-select
              v-model="filter.collectionId"
              :label="`${$t('medicalTests.collection')} *`"
              outlined
              dense
              :items="activeCollections"
              item-text="description"
              item-value="id"
              :loading="isLoadingCollections"
              :disabled="!activeCollections.length"
            />
          </v-flex>
          <v-flex xs12 sm4 lg4>
            <v-select
              v-model="filter.subgroupId"
              :label="`${$t('medicalTests.subgroup')} *`"
              outlined
              dense
              class="mr-sm-1"
              :items="activeSubGroups"
              item-text="description"
              item-value="id"
              :loading="isLoadingSubGroups"
              :disabled="!activeSubGroups.length"
            />
          </v-flex>
          <v-flex xs12 sm8 lg8>
            <v-text-field
              v-model="filter.query"
              filled
              flat
              rounded
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('medicalTests.search')"
              hide-details
              class="mb-6"
            />
          </v-flex>
        </v-layout>

        <v-card>
          <v-data-table
            :headers="headers"
            :items="activeActs"
            :loading="isLoadingActs"
            :loading-text="$t('common.cargando')"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            item-key="code"
            :no-data-text="$t('medicalTests.no_data')"
            @page-count="pageCount = $event"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left slotClickable">
                  {{ item.specialty.description }}
                </td>
                <td class="text-left slotClickable">
                  {{ item.group.description }}
                </td>
                <td class="text-left slotClickable">
                  {{ item.collection.description }}
                </td>
                <td class="text-left slotClickable">
                  {{ item.subgroup.description }}
                </td>
                <td class="text-left slotClickable">
                  {{ item.code }}
                </td>
                <td class="text-left slotClickable">
                  {{ item.description }}
                </td>

                <td class="text-center">
                  <v-menu v-if="item.professional" bottom right dense>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="grey" v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="edit(item)">
                        <v-list-item-title>{{ $t('common.edit') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openDeleteModal(item)">
                        <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template slot="loading">
              <v-skeleton-loader loading type="table-row-divider@6" />
            </template>
          </v-data-table>
        </v-card>
        <div class="text-center mt-3">
          <v-pagination v-model="page" :length="paginationLength" circle />
        </div>
      </v-card>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('medicalTests.delete_act') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="dialogDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p>{{ $t('medicalTests.confirm_delete_act') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialogDelete = false">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="red darken-1" depressed dark :loading="deleting" :disabled="deleting" @click="deleteAct()">{{
            $t('common.confirm')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { baseNameRoutes } from '@/router/paths';
import { ref } from 'vue';
import { useNomenclator } from '@/use/MedicalTests/useNomenclator';
import { getHeader, medicalTestsUrl } from '@/config/config';
import { mapActions } from 'vuex';

const ACTS_ENDPOINT = medicalTestsUrl + 'acts';

function isAdmin(user) {
  return user && typeof user['rol'] !== 'undefined' && user['rol'] === 1;
}

export default {
  setup: function(props, context) {
    const {
      filter,
      activeSpecialities,
      activeGroups,
      activeCollections,
      activeSubGroups,
      activeActs,
      isLoading,
      isLoadingSpecialties,
      isLoadingGroups,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      page,
      pageCount,
      itemsPerPage,
      loadSpecialties,
      loadGroups,
      loadCollections,
      loadSubGroups,
      loadActs,
      paginationLength,
      cleanSpecialty,
      removeAct,
    } = useNomenclator(
      JSON.parse(window.localStorage.getItem('auth_ehealth')).user.professional_id,
      true,
      isAdmin(JSON.parse(window.localStorage.getItem('auth_ehealth')).user)
    );

    const headers = ref([
      { text: context.root.$t('medicalTests.specialty'), align: 'left', value: 'specialty', width: '10%' },
      { text: context.root.$t('medicalTests.group'), align: 'left', value: 'group', width: '10%' },
      { text: context.root.$t('medicalTests.collection'), align: 'left', value: 'collection', width: '10%' },
      { text: context.root.$t('medicalTests.subgroup'), align: 'left', value: 'subgroup', width: '10%' },
      { text: context.root.$t('medicalTests.codeCiepq'), align: 'left', value: 'code', width: '10%' },
      { text: context.root.$t('medicalTests.description'), align: 'left', value: 'description', width: '25%' },
      { text: context.root.$t('common.actions'), align: 'center', value: 'actions', sortable: false, width: '10%' },
    ]);

    const paths = ref({
      createNomenclator: baseNameRoutes.createNomenclator,
      editNomenclator: baseNameRoutes.editNomenclator,
    });

    loadSpecialties();
    loadActs();

    return {
      filter,
      activeSpecialities,
      activeGroups,
      activeCollections,
      activeSubGroups,
      activeActs,
      isLoading,
      isLoadingSpecialties,
      isLoadingGroups,
      isLoadingCollections,
      isLoadingSubGroups,
      isLoadingActs,
      loadSpecialties,
      loadGroups,
      loadCollections,
      loadSubGroups,
      loadActs,
      page,
      pageCount,
      itemsPerPage,
      headers,
      paths,
      paginationLength,
      cleanSpecialty,
      removeAct,
    };
  },
  data: function() {
    return {
      dialogDelete: false,
      deleting: false,
      actToDelete: null,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },
  computed: {
    isAdmin() {
      return isAdmin(this.authUser);
    },
  },
  methods: {
    ...mapActions(['showAlert']),
    edit(item) {
      this.$router.push({ name: this.paths.editNomenclator, params: { id: item.id } });
    },
    openDeleteModal(item) {
      this.actToDelete = item;
      this.dialogDelete = true;
    },
    async deleteAct() {
      this.deleting = true;

      try {
        const response = await fetch(`${ACTS_ENDPOINT}/${this.actToDelete.id}`, {
          method: 'DELETE',
          headers: getHeader(),
        });

        if (response.status === 200) {
          this.showAlertMsg('success', 'mdi-check', this.$t('medicalTests.create_test_success'));
          this.removeAct(this.actToDelete);
          this.deleting = false;
          this.dialogDelete = false;
          this.actToDelete = null;
        } else {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
        }
        this.deleting = false;
      } catch (e) {
        if (e.data && e.data.error) {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t(e.data.error));
        } else {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
        }
      } finally {
        this.deleting = false;
      }
    },
    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },
    canManage(item) {
      return (this.isAdmin && item.professionalId === null) || (!this.isAdmin && item.professionalId);
    },
  },
};
</script>
